@keyframes walkAnimation_down {
  from {
    transform: translate3d(-75%, 0%, 0);
  }
  to {
    transform: translate3d(-100%, 0%, 0);
  }
}
@keyframes walkAnimation_up {
  from {
    transform: translate3d(-25%, 0%, 0);
  }
  to {
    transform: translate3d(-50%, 0%, 0);
  }
}
@keyframes walkAnimation_left {
  from {
    transform: translate3d(-50%, 0%, 0);
  }
  to {
    transform: translate3d(-75%, 0%, 0);
  }
}
@keyframes walkAnimation_right {
  from {
    transform: translate3d(0%, 0%, 0);
  }
  to {
    transform: translate3d(-25%, 0%, 0);
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.9;
  }
}
@keyframes rollUp {
  from {
    position: relative;
    top: 20px;
    opacity: 0;
  }
  to {
    position: relative;
    top: 0px;
    opacity: 1;
  }
}
